import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { HtmlContent } from './html-content'

export function RaffleBanner() {
  const i18n = I18n.useI18n()

  const location = ReactRouter.useLocation()
  const isRafflePage = location.pathname.includes('/raffle')
  const isGameplay = location.pathname.includes('/games')
  const isSportsbookPage = location.pathname.includes('/sports')

  if (isGameplay || isSportsbookPage || isRafflePage) {
    return null
  }

  return (
    <ReactRouter.Link to={`/raffle`}>
      <Common.Box
        width="100%"
        background="#E51953"
        height="48px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="600"
        color="white"
      >
        <HtmlContent
          html={{
            __html: i18n.translate('raffle.topbar.description'),
          }}
        />
      </Common.Box>
    </ReactRouter.Link>
  )
}
