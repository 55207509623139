import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { TimestampProvider } from '@rushplay/common'

import Router from '../router'
import { FastTrack } from '../fast-track'
import { GlobalStyles, ThemeProvider } from '../theme'
import { RaffleCampaignsProvider } from '../components/common/raffle-page/use-fetch-raffle-campaigns'
import { SseConnectionProvider } from '../use-sse-listener'
import { TranslationProvider } from '../translations'

/**
 * Background needs to be here since background
 * needs to be transparent for route /callbacks (for iframe usage)
 */
const Background = styled.div`
  background: ${t.color('background')};
`

export class App extends React.PureComponent {
  constructor(props) {
    super(props)

    props.onFetch()
    props.onLanguageChange()
    props.onRestore()

    this.handleKeepAlive = this.handleKeepAlive.bind(this)
  }

  componentDidMount() {
    if (this.props.sessionToken) {
      document.addEventListener('visibilitychange', this.handleKeepAlive)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionToken !== this.props.sessionToken) {
      this.props.onSessionChange()
      document.removeEventListener('visibilitychange', this.handleKeepAlive)

      if (!this.props.sessionToken) {
        this.props.onRestore()
      }

      if (this.props.sessionToken) {
        document.addEventListener('visibilitychange', this.handleKeepAlive)
      }
    }
    if (prevProps.playerRestricted && !this.props.playerRestricted) {
      this.props.onPlayerRestrictionRemoved()
    }
  }

  handleKeepAlive() {
    if (!document.hidden) {
      this.props.onSessionCheck()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleKeepAlive)
  }

  render() {
    return (
      <ThemeProvider>
        <Background>
          <FastTrack />
          <GlobalStyles />
          <TranslationProvider>
            <TimestampProvider>
              <SseConnectionProvider>
                <RaffleCampaignsProvider>
                  <Router
                    as={this.props.router}
                    basename={this.props.basename}
                    history={this.props.history}
                  />
                </RaffleCampaignsProvider>
              </SseConnectionProvider>
            </TimestampProvider>
          </TranslationProvider>
        </Background>
      </ThemeProvider>
    )
  }
}

App.defaultProps = {
  onFetch: () => {},
}

App.propTypes = {
  basename: PropTypes.string,
  history: PropTypes.object,
  playerRestricted: PropTypes.bool,
  router: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  sessionToken: PropTypes.string,
  store: PropTypes.object,
  onFetch: PropTypes.func,
  onSessionCheck: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func,
  onPlayerRestrictionRemoved: PropTypes.func,
  onRestore: PropTypes.func,
  onSessionChange: PropTypes.func,
}
