import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import { HtmlContent } from '../html-content'

import * as Icons from './icons'

const Wrapper = styled.div`
  font-family: Inter;
  font-weight: 400;
  padding: 20px 14px;
  border-radius: 0px 0px 4px 4px;
  font-size: 16px;
  color: #0c0c0c;
  width: 100%;
  background: #bfa4ff;

  & ol,
  ul,
  li {
    color: #0c0c0c !important;
  }
`

function FAQTab(props) {
  return (
    <Common.Box
      style={{
        cursor: props.active ? 'initial' : 'pointer',
      }}
      color="white"
      fontFamily="Inter"
      fontSize="18px"
      fontweight="600"
      padding="15px 20px"
      borderBottom={props.active ? '6px solid #9477D9' : 'none'}
      onClick={() => props.handleTab(props.tabName)}
    >
      {props.children}
    </Common.Box>
  )
}

FAQTab.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool.isRequired,
  tabName: PropTypes.string.isRequired,
  handleTab: PropTypes.func.isRequired,
}

function InfoItem(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box display="flex" width="100%" flexDirection="column">
      <Common.Box
        fontFamily="Inter"
        color="white"
        style={{ cursor: 'pointer' }}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="20px 14px"
        background="#9477D9"
        fontSize="14px"
        borderRadius={props.active ? '4px 4px 0px 0px' : '4px'}
        onClick={() => props.handleInfo(props.name)}
      >
        {i18n.translate(props.title)}
        <Common.Box as="span" color="#1B0434">
          <Icons.Chevron />
        </Common.Box>
      </Common.Box>
      {props.active && (
        <Wrapper>
          <HtmlContent
            html={{
              __html: i18n.translate(props.content),
            }}
          />
        </Wrapper>
      )}
    </Common.Box>
  )
}

InfoItem.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleInfo: PropTypes.func.isRequired,
}

export function RaffleFAQSection() {
  const i18n = I18n.useI18n()
  const [active, setActive] = React.useState('faq')
  const [activeInfo, setActiveInfo] = React.useState(null)

  function handleTab(tabName) {
    setActive(tabName)
  }

  function handleInfo(infoName) {
    if (infoName === activeInfo) {
      return setActiveInfo(null)
    }

    return setActiveInfo(infoName)
  }

  return (
    <Common.Box
      pb="40px"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      pt={['20px', '120px']}
    >
      <Common.Box width="100%" display="flex">
        <FAQTab tabName="faq" active={active === 'faq'} handleTab={handleTab}>
          {i18n.translate('raffle-page.faq-terms.faq')}
        </FAQTab>
        <FAQTab
          tabName="terms"
          active={active === 'terms'}
          handleTab={handleTab}
        >
          {i18n.translate('raffle-page.faq-terms.terms')}
        </FAQTab>
      </Common.Box>
      <Common.Box
        pt="32px"
        width="100%"
        display="flex"
        flexDirection="column"
        style={{ gap: '24px' }}
      >
        {active === 'faq'
          ? Array.from({ length: 11 }, (_, index) => index + 1).map(el => (
              <InfoItem
                key={el}
                active={activeInfo === `faq-item-${el}`}
                name={`faq-item-${el}`}
                title={`raffle-page.faq.${el}.title`}
                content={`raffle-page.faq.${el}.content`}
                handleInfo={handleInfo}
              />
            ))
          : Array.from({ length: 1 }, (_, index) => index + 1).map(el => (
              <InfoItem
                key={el}
                active={activeInfo === `terms-item-${el}`}
                name={`terms-item-${el}`}
                title={`raffle-page.terms-and-conditions.${el}.title`}
                content={`raffle-page.terms-and-conditions.${el}.content`}
                handleInfo={handleInfo}
              />
            ))}
      </Common.Box>
    </Common.Box>
  )
}
