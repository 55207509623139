import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { DescriptionHeadingTypography } from './components'
import { RaffleTimer } from './raffle-timer'

function extractDayFromTimestamp(timestamp) {
  if (!timestamp) {
    return ''
  }

  const date = new Date(timestamp)

  return date.getDate().toString() // Returns the day of the month as a string
}

extractDayFromTimestamp.propTypes = {
  timestamp: PropTypes.string.isRequired,
}

function Upcoming(props) {
  const i18n = I18n.useI18n()
  return (
    <Common.Box
      fontFamily='"Source Sans 3", serif'
      color="white"
      borderRadius="4px"
      fontSize="21px"
      fontWeight="600"
      py="2px"
      px="4px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textTransform="uppercase"
      height="36px"
      style={{
        pointerEvents: 'none',
        zIndex: 10,
      }}
      background="rgba(0, 0, 0, 1)"
    >
      {i18n.translate(`raffle-page.card.${props.id}.label.upcoming`, {
        activeFrom: extractDayFromTimestamp(props.activeFrom),
        activeTo: extractDayFromTimestamp(props.activeTo),
      })}
    </Common.Box>
  )
}

Upcoming.propTypes = {
  id: PropTypes.string.isRequired,
  activeFrom: PropTypes.string.isRequired,
  activeTo: PropTypes.string.isRequired,
}

function Running(props) {
  const i18n = I18n.useI18n()
  return (
    <Common.Box
      style={{ zIndex: 10 }}
      display="flex"
      flexDirection={['column', 'column']}
      alignItems={['flex-end', 'flex-end']}
    >
      <Common.Box
        style={{ textTransform: 'uppercase' }}
        fontwWight="600"
        fontFamily='"Source Sans 3", serif'
        fontSize={['8px', '18px']}
        pb={['5px', '5px']}
        pr={['0px', '0px']}
        as="p"
      >
        {i18n.translate('raffle-page.card.label.running')}
      </Common.Box>
      <RaffleTimer timestamp={props.activeTo} />
    </Common.Box>
  )
}

Running.propTypes = {
  activeTo: PropTypes.string.isRequired,
}

function Winner() {
  const i18n = I18n.useI18n()
  return (
    <Common.Box
      fontFamily='"Source Sans 3", serif'
      color="#0C0C0C"
      borderRadius="4px"
      background="#57FF68"
      fontSize="14px"
      fontWeight="600"
      py="2px"
      px="4px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        pointerEvents: 'none',
        zIndex: 10,
      }}
    >
      {i18n.translate('raffle-page.card.label.done')}
    </Common.Box>
  )
}

function LabelComponent(props) {
  switch (props.status) {
    case 'running':
      return <Running {...props} />
    case 'winner':
      return <Winner />
    case 'future' || 'scheduled':
      return <Upcoming {...props} />
    default:
      return null
  }
}

LabelComponent.propTypes = {
  id: PropTypes.string.isRequired,
  activeFrom: PropTypes.string.isRequired,
  activeTo: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

function GrandLabel() {
  const i18n = I18n.useI18n()
  return (
    <Common.Box
      position="absolute"
      top="-20px"
      width="173px"
      height="30px"
      background="linear-gradient(180deg, #F8E361 25.76%, #F5C003 75.76%)"
      borderRadius="4px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.10)"
      left="50%"
      right="50%"
      style={{
        transform: 'translate(-50%, 0%)',
      }}
    >
      <Common.Box
        as="p"
        fontSize="21px"
        color="#1B0434"
        fontFamily='"Source Sans 3", serif'
        fontWeight="700"
        width="100%"
        style={{
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        {i18n.translate('raffle-page.card.label.grand')}
      </Common.Box>
    </Common.Box>
  )
}

export function RaffleCard(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box
      maxWidth="540px"
      width="100%"
      minHeight="160px"
      borderRadius="4px"
      border={
        props.isFinal
          ? '4px solid #FBF792'
          : '1px solid rgba(255, 255, 255, 0.20)'
      }
      backgroundImage="linear-gradient(180deg, rgba(82, 34, 147, 0.70) 0%, rgba(136, 160, 255, 0.70) 50%, rgba(82, 34, 147, 0.70) 100%)"
      py="16px"
      px="20px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
    >
      {props.isFinal && <GrandLabel />}
      <Common.Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Common.Box
          fontwWight="600"
          fontFamily='"Source Sans 3", serif'
          fontSize={['16px', '24px']}
          as="p"
          color="white"
          style={{
            textTransform: 'uppercase',
          }}
        >
          {i18n.translate('raffle-page.card.week', {
            week: Number(props.index + 1),
          })}
        </Common.Box>
        <LabelComponent
          activeTo={props.activeTo}
          status={props.status}
          activeFrom={props.activeFrom}
          id={props.id}
        />
      </Common.Box>
      <Common.Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="flex-end"
      >
        <DescriptionHeadingTypography>
          {i18n.translate(`raffle-page.card.${props.id}.prize`)}
        </DescriptionHeadingTypography>

        {props.status === 'winner' ? (
          <Common.Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Common.Box
              as="p"
              color="white"
              style={{ textTransform: 'uppercase' }}
              fontwWight="600"
              fontFamily='"Source Sans 3", serif'
              fontSize="14px"
              pb={'5px'}
            >
              {i18n.translate(`raffle-page.card.${props.id}.winner.title`)}
            </Common.Box>

            {props.isWinner ? (
              <DescriptionHeadingTypography>
                {i18n.translate(`raffle-page.card.winner.me`)}
              </DescriptionHeadingTypography>
            ) : props.winnerId ? (
              <DescriptionHeadingTypography>
                {props.winnerId}
              </DescriptionHeadingTypography>
            ) : (
              <Common.Box
                style={{ textAlign: 'right', textTransform: 'uppercase' }}
                as="p"
                color="white"
                fontwWight="600"
                fontFamily='"Source Sans 3", serif'
                fontSize="14px"
                pb={'5px'}
              >
                {i18n.translate(
                  `raffle-page.card.${props.id}.winner-pending.title`
                )}
              </Common.Box>
            )}
          </Common.Box>
        ) : (
          <Common.Box
            width="50%"
            height="100%"
            position="absolute"
            bottom="0px"
            right="0px"
            backgroundImage={[
              `url(${i18n.translate(
                `raffle-page.card.${props.id}.image.mobile`
              )})`,
              `url(${i18n.translate(`raffle-page.card.${props.id}.image`)})`,
            ]}
            backgroundSize="auto auto"
            backgroundPosition="90% 80%"
            backgroundRepeat="no-repeat"
            style={{
              zIndex: 1,
            }}
          />
        )}
      </Common.Box>
    </Common.Box>
  )
}

RaffleCard.propTypes = {
  id: PropTypes.string.isRequired,
  activeFrom: PropTypes.string.isRequired,
  activeTo: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  winnerId: PropTypes.string,
  isWinner: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isFinal: PropTypes.bool.isRequired,
}
