import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as Configuration from '../configuration'
import * as CombinedSelectors from '../combined-selectors'
import * as Icons from '../components/icons.js'
import * as Lookup from '../lookup'
import * as Ui from '../ui'
import { Card } from '../components/common/card'
import { H2 } from '../components/common/headings'
import { HtmlContent } from '../components/common/html-content'
import { Logotype } from '../components/common/logotype'
import {
  PrimaryButton,
  PrimaryButtonLink,
} from '../components/common/button-simple'
import { RaffleBanner } from '../components/common/raffle-banner'

import { CustomisableRows } from './customisable-rows'
import { PaymentProviderImages } from './payment-providers'

const TopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 36px;
  padding-bottom: 64px;

  &:before {
    content: '';
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;

    ${css({
      backgroundColor: 'simpleorange',
      clipPath: [
        'ellipse(150% 285px at 50% 0%)',
        null,
        'ellipse(100% 285px at 50% 0%)',
      ],
    })}
  }
`

export function LandingPage(props) {
  const i18n = useI18n()
  const welcomeOffer = ReactRedux.useSelector(CombinedSelectors.getWelcomeOffer)
  const maxBonusCents = R.pathOr(0, ['bonusMoneyPercentMaxCents'], welcomeOffer)
  const dispatch = ReactRedux.useDispatch()
  const countryCode = ReactRedux.useSelector(state =>
    Lookup.getCountryCode(state.lookup)
  )

  const isRaffleEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleEnabled(state.configuration)
  )
  const isRaffleLinkEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleLinkEnabled(state.configuration)
  )

  return (
    <React.Fragment>
      <TopWrapper showWelcomeOffer={props.showWelcomeOffer}>
        <Logotype height="156px" variant="alt" />
        <Card
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderStyle="solid"
          borderWidth="8px"
          borderColor="white"
          backgroundPosition="center"
          backgroundImage="url('/images/simplecasino/LP-card-bg.png')"
          backgroundSize="auto"
          color="darkblue"
          textAlign="center"
          fontWeight="700"
          mt="3"
        >
          {props.showWelcomeOffer ? (
            <React.Fragment>
              <Common.Box
                fontWeight="600"
                fontSize="1"
                lineHeight="20px"
                maxWidth="140px"
              >
                {i18n.translate('landing-page.simple.welcome-offer')}
              </Common.Box>
              <Common.Text fontSize="60px">
                {i18n.translate('bonus-offer-badge.middle', {
                  amount: maxBonusCents,
                })}
              </Common.Text>
              <Common.Box my="0" fontSize="2" lineHeight="28px">
                <HtmlContent
                  html={{
                    __html: i18n.translate(
                      'landing-page.simple.play-now.description.welcome-offer'
                    ),
                  }}
                />
              </Common.Box>
            </React.Fragment>
          ) : (
            <Common.Box fontSize="2" lineHeight="20px">
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    'landing-page.simple.play-now.description'
                  ),
                }}
              />
            </Common.Box>
          )}
          <Common.Space mt="1">
            {countryCode !== 'FI' ? (
              <PrimaryButton
                small
                onClick={() => dispatch(Ui.toggleVisibility('register', true))}
              >
                {i18n.translate('landing-page.simple.play-now.cta')}
              </PrimaryButton>
            ) : (
              <PrimaryButtonLink small to="/login">
                {i18n.translate('landing-page.simple.play-now.cta')}
              </PrimaryButtonLink>
            )}
          </Common.Space>
          <Common.Space mt="1">
            <Common.Text
              as={Common.Link}
              to="/login"
              fontWeight="600"
              textDecoration="underline"
            >
              {i18n.translate(`landing-page.login`)}
            </Common.Text>
          </Common.Space>
        </Card>
      </TopWrapper>
      {isRaffleEnabled && isRaffleLinkEnabled ? <RaffleBanner /> : null}

      <Common.Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="5"
      >
        <PaymentProviderImages />
      </Common.Box>

      <Common.Flex flexDirection="column" flexWrap="wrap" alignItems="center">
        <Common.Box
          width="90%"
          display="grid"
          flexGrow="2"
          gridTemplateColumns="repeat(auto-fit, minmax(240px, 1fr))"
          gridTemplateRows="repeat(auto-fit,1fr)"
          gridColumnGap="3"
          gridRowGap="1"
        >
          <Common.Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="center"
            color="simpleorange"
          >
            <Common.Box
              as={Common.Link}
              to="/login"
              height="56px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              mx="auto"
            >
              <Common.Space pr="0">
                <H2 style={{ fontSize: '24px', lineHeight: '28px' }}>
                  {i18n.translate(`landing-page.step0.title`)}
                </H2>
              </Common.Space>
              <Common.Space pt="4px">
                <Icons.ArrowEast size="20px" />
              </Common.Space>
            </Common.Box>
            <Common.Box
              color="darkgray"
              lineHeight={1.5}
              fontWeight="600"
              py="0"
            >
              {i18n.translate(`landing-page.step0.content`)}
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Common.Text
                as={Common.Link}
                to={countryCode === 'FI' ? '/login' : '/?register=me'}
                fontWeight="600"
                textDecoration="underline"
              >
                <Common.Space p={0}>
                  {i18n.translate(`landing-page.link.login`)}
                </Common.Space>
              </Common.Text>
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="center"
            color="simpleorange"
          >
            <Common.Box
              as={Common.Link}
              to="/casino"
              height="56px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              mx="auto"
            >
              <Common.Space pr="0">
                <H2 style={{ fontSize: '24px', lineHeight: '28px' }}>
                  {i18n.translate(`landing-page.step1.title`)}
                </H2>
              </Common.Space>
              <Common.Space pt="4px">
                <Icons.ArrowEast size="20px" />
              </Common.Space>
            </Common.Box>
            <Common.Box
              color="darkgray"
              lineHeight={1.5}
              fontWeight="600"
              py="0"
            >
              {i18n.translate(`landing-page.step1.content`)}
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Common.Text
                as={Common.Link}
                to="/casino"
                fontWeight="600"
                textDecoration="underline"
              >
                <Common.Space p={0}>
                  {i18n.translate(`landing-page.link.casino`)}
                </Common.Space>
              </Common.Text>
              <Common.Text
                as={Common.Link}
                to="casino/live-casino"
                fontWeight="600"
                textDecoration="underline"
              >
                <Common.Space p={0}>
                  {i18n.translate(`landing-page.link.live-casino`)}
                </Common.Space>
              </Common.Text>
              <Common.Text
                as={Common.Link}
                to="/sports"
                fontWeight="600"
                textDecoration="underline"
              >
                <Common.Space p={0}>
                  {i18n.translate(`landing-page.link.sports`)}
                </Common.Space>
              </Common.Text>
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="center"
            color="simpleorange"
          >
            <Common.Box
              as={Common.Link}
              to="/login"
              height="56px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              mx="auto"
            >
              <Common.Space pr="0">
                <H2 style={{ fontSize: '24px', lineHeight: '28px' }}>
                  {i18n.translate(`landing-page.step2.title`)}
                </H2>
              </Common.Space>
              <Common.Space pt="4px">
                <Icons.ArrowEast size="20px" />
              </Common.Space>
            </Common.Box>
            <Common.Box
              color="darkgray"
              lineHeight={1.5}
              fontWeight="600"
              py="0"
            >
              {i18n.translate(`landing-page.step2.content`)}
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Common.Text
                as={Common.Link}
                to="/login"
                fontWeight="600"
                textDecoration="underline"
              >
                <Common.Space p={0}>
                  {i18n.translate(`landing-page.link.payments`)}
                </Common.Space>
              </Common.Text>
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box
          mx={1}
          my={3}
          width={['calc(100vw - 50px)', null, null, 'calc(100vw - 200px)']}
          borderTopStyle="solid"
          borderColor="simpleorange"
          borderWidth="4px"
          pt="3"
        >
          <CustomisableRows
            arrow
            hideButton
            titleColor="simpleorange"
            titleFontSize="3"
          />
        </Common.Box>
      </Common.Flex>
    </React.Fragment>
  )
}

LandingPage.propTypes = {
  showWelcomeOffer: PropTypes.bool.isRequired,
}
