import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Session from '@rushplay/session'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Ui from '../../../ui'
import * as Constants from '../../../constants'
import { HtmlContent } from '../html-content'

import {
  DescriptionHeadingTypography,
  DescriptionTypography,
  DesktopHeroImage,
  Divider,
  RaffleLogo,
} from './components'
import { RaffleButton } from './raffle-button'
import { RaffleCard } from './raffle-card'
import { RaffleFAQSection } from './faq-section'
import { RaffleTimer } from './raffle-timer'
import { useRaffleCampaigns } from './use-fetch-raffle-campaigns'

const MAX_CONTENT_WIDTH = '540px'

const Overlay = styled.div`
  ${props =>
    css({
      width: '372px',
      height: '512px',
      backgroundImage: `url(${props.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: '125% 251px',
      pointerEvents: 'none',
      display: 'none',
      position: 'absolute',
      right: '0px',
      bottom: '0px',
    })};

  @media (max-width: 580px) {
    display: block;
  }
`

function WelcomeSection(props) {
  const i18n = I18n.useI18n()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isSessionActive(state.session)
  )
  return (
    <Common.Box
      width="100%"
      backgroundImage={[
        `url(${i18n.translate('raffle-page.section-1.background-mobile')})`,
        `url(${i18n.translate('raffle-page.section-1.background')})`,
      ]}
      backgroundPosition="center center, center center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      px={2}
      position="relative"
      pb="77px"
    >
      <Overlay
        background={i18n.translate(
          'raffle-page.section-1.background-mobile.overlay'
        )}
      />
      <Common.Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        maxWidth={MAX_CONTENT_WIDTH}
        pt="24px"
        zIndex="1"
      >
        <RaffleLogo
          background={i18n.translate('raffle-page.section-1.logo')}
          backgroundMobile={i18n.translate('raffle-page.section-1.logo-mobile')}
        />
        <Common.Box pt="24px" width="100%">
          <DesktopHeroImage
            background={i18n.translate('raffle-page.section-1.hero-image')}
          />
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          pt="24px"
          px={['10px', '0px']}
        >
          <DescriptionTypography>
            <HtmlContent
              html={{
                __html: i18n.translate('raffle-page.section-1.description'),
              }}
            />
          </DescriptionTypography>
          <Common.Box pt={2}>
            <DescriptionHeadingTypography>
              {i18n.translate('raffle-page.section-1.rules.title')}
            </DescriptionHeadingTypography>
          </Common.Box>
          <Common.Box pt={[0, 2]}>
            <DescriptionTypography>
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    'raffle-page.section-1.rules.description'
                  ),
                }}
              />
            </DescriptionTypography>
          </Common.Box>
        </Common.Box>

        <Common.Box
          display="flex"
          width="100%"
          pt="48px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <ReactRouter.Link
            to={
              authenticated ? `/casino/games/${props.gameId}` : `?register=me`
            }
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <RaffleButton type="primary">
              {authenticated
                ? i18n.translate('raffle-page.section-1.buttons.play')
                : i18n.translate('raffle-page.section-1.buttons.register')}
            </RaffleButton>
          </ReactRouter.Link>
          <Common.Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            pt={1}
          >
            {authenticated ? (
              <RaffleButton type="secondary" disabled>
                {i18n.translate('raffle-page.section-1.tickets-total', {
                  tickets: props.tickets,
                })}
              </RaffleButton>
            ) : (
              <ReactRouter.Link
                to={`/login`}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <RaffleButton type="primary">
                  {i18n.translate('raffle-page.section-1.login')}
                </RaffleButton>
              </ReactRouter.Link>
            )}
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <Divider
        src={i18n.translate('raffle-page.divider')}
        alt="divider"
        width="100%"
      />
    </Common.Box>
  )
}

WelcomeSection.propTypes = {
  tickets: PropTypes.number,
  gameId: PropTypes.string,
}

function RaffleContent(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  const authenticated = ReactRedux.useSelector(state =>
    Session.isSessionActive(state.session)
  )
  const currentCampaign =
    props.campaigns?.find(campaign => campaign.status === 'running') || []

  function handleDeposit() {
    dispatch(Ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true))
  }

  return (
    <Common.Box
      background="#1b0434"
      width="100%"
      height="100%"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
    >
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        maxWidth={MAX_CONTENT_WIDTH}
        pt="64px"
        pb="80px"
        px={1}
      >
        <Common.Box
          display="flex"
          justifyContent={['center', 'flex-start']}
          pt="32px"
          width="100%"
        >
          <DescriptionHeadingTypography>
            {i18n.translate('raffle-page.section-2.header')}
          </DescriptionHeadingTypography>
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          pb="40px"
          pt={['12px', '24px']}
          width="100%"
          alignItems={['center', 'flex-start']}
        >
          <DescriptionTypography>
            <HtmlContent
              html={{
                __html: i18n.translate('raffle-page.section-2.description'),
              }}
            />
          </DescriptionTypography>
          <Common.Box pl={3} as="ul" pt={1}>
            {currentCampaign?.rewards?.map(reward => (
              <Common.Box
                as="li"
                key={`${reward.amount}${reward.kind}`}
                style={{
                  listStyle: 'disc',
                }}
              >
                <DescriptionTypography>
                  <HtmlContent
                    html={{
                      __html: i18n.translate(
                        `raffle-page.section-2.reward.${currentCampaign.key}.${reward.kind}.${reward.amount}`,
                        {
                          amount: reward.amount,
                          depositAmountFrom:
                            reward.deposit_amount_from_cents / 100,
                          depositAmountTo: reward.deposit_amount_to_cents / 100,
                        }
                      ),
                    }}
                  />
                </DescriptionTypography>
              </Common.Box>
            ))}
          </Common.Box>
        </Common.Box>
        <Common.Box
          alignItems="center"
          pb="40px"
          display="flex"
          flexDirection={['column', 'column']}
        >
          <Common.Box
            as="p"
            pb={1}
            fontSize={['12px', '18px']}
            color="white"
            fontWegith="600"
            fontFamily='"Source Sans 3", serif'
            style={{ textTransform: 'uppercase' }}
          >
            {i18n.translate('raffle-page.section-2.raffle-drawn-in')}
          </Common.Box>
          <RaffleTimer timestamp={currentCampaign?.activeTo} />
        </Common.Box>
        <Common.Box width="100%" pb="40px">
          {authenticated ? (
            <RaffleButton onClick={handleDeposit}>
              {i18n.translate('raffle-page.section-2.deposit')}
            </RaffleButton>
          ) : (
            <ReactRouter.Link
              to={`/login`}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RaffleButton>
                {i18n.translate('raffle-page.section-2.deposit')}
              </RaffleButton>
            </ReactRouter.Link>
          )}
        </Common.Box>
        <Common.Box width="100%" pt="40px" pb="40px">
          <Common.Box display="flex" justifyContent="center" width="100%">
            <DescriptionHeadingTypography>
              {i18n.translate('raffle-page.section-2.raffle-cards.header')}
            </DescriptionHeadingTypography>
          </Common.Box>
          <Common.Box
            style={{ gap: '40px' }}
            py={1}
            width="100%"
            display="flex"
            flexDirection="column"
          >
            {props.campaigns
              ?.sort((a, b) => new Date(a.activeFrom) - new Date(b.activeFrom))
              .map((campaign, i) => (
                <RaffleCard
                  index={i}
                  key={campaign?.key}
                  id={campaign?.key}
                  activeFrom={campaign?.activeFrom}
                  activeTo={campaign?.activeTo}
                  status={campaign?.status}
                  winnerId={campaign?.winnerId}
                  isWinner={campaign?.isWinner}
                  isFinal={Boolean(props.campaigns.length === i + 1)}
                />
              ))}
          </Common.Box>
        </Common.Box>
        <RaffleFAQSection />
        <Common.Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          fontSize="16px"
          color="white"
        >
          <Common.Box>
            {i18n.translate('raffle-page.section-2.help')}
          </Common.Box>
          <Common.Box
            pt={0}
            style={{ textDecoration: 'underline' }}
            color="white"
            as="a"
            href={'mailto:support@simplecasino.com'}
          >
            {i18n.translate('raffle-page.section-2.contact')}
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

RaffleContent.propTypes = {
  campaigns: PropTypes.array.isRequired,
}

export function Raffle() {
  const { data, invalidate } = useRaffleCampaigns()

  React.useEffect(() => {
    invalidate()
  }, [])

  return (
    <Common.Box
      minHeight="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Common.Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <WelcomeSection gameId={data?.game_id} tickets={data?.tickets} />
        <RaffleContent campaigns={data?.campaigns} />
      </Common.Box>
    </Common.Box>
  )
}

export default Raffle
