import { color } from 'styled-system'

import styled from '@emotion/styled'
import { css } from '@styled-system/css'

export const PageWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  ${color};
  ${props =>
    css({
      py: [null, 3, 2],
      px: props.disabledPadding ? null : [null, 2, 1],
    })};
`
