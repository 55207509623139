import css from '@styled-system/css'
import styled from '@emotion/styled'

// 100k Raffle Logotype
export const RaffleLogo = styled.div`
  ${props =>
    css({
      width: ['335px', null, '394px'],
      height: ['68px', null, '104px'],
      backgroundImage: [
        `url(${props.backgroundMobile})`,
        null,
        `url(${props.background})`,
      ],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      pointerEvents: 'none',
    })};
`

// Hero image. Visible only on Desktop.
export const DesktopHeroImage = styled.div`
  ${props =>
    css({
      width: '541px',
      height: '351px',
      backgroundImage: `url(${props.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      pointerEvents: 'none',
    })};

  @media (max-width: 580px) {
    display: none;
  }
`

// Typography

export const DescriptionTypography = styled.p`
  ${css({
    fontSize: ['16px', '21px'],
    fontWeight: '600',
    fontFamily: '"Source Sans 3", serif',
    color: 'white!important',
  })};

  & span {
    color: #fade0c;
  }

  & ul,
  li {
    color: white !important;
  }
`

export const DescriptionHeadingTypography = styled.h3`
  ${css({
    fontSize: ['22px', '32px'],
    fontWeight: '900',
    fontFamily: '"Source Sans 3", serif',
    color: '#FADE0C',
    background: 'linear-gradient(180deg, #F8E361 25.76%, #F5C003 75.76%)',
    backgroundClip: 'text',
    textTransform: 'uppercase',
    textShadow: '-2px 3px 2px #6B1983',
    textAlign: ['center', 'left'],
  })};
`

// Elements

export const Divider = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
`
