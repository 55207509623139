import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import * as Icons from './icons'

export function RaffleTimer(props) {
  const [timeLeft, setTimeLeft] = React.useState('')

  React.useEffect(() => {
    if (!props.timestamp) {
      return
    }

    const targetTime = new Date(props.timestamp).getTime()

    function updateCountdown() {
      const now = Date.now()
      const difference = targetTime - now

      if (difference <= 0) {
        setTimeLeft('0D 0H 0M 0S')
        return
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTimeLeft(`${days}D ${hours}H ${minutes}M ${seconds}S`)
      requestAnimationFrame(updateCountdown)
    }

    const animationFrame = requestAnimationFrame(updateCountdown)

    return () => cancelAnimationFrame(animationFrame)
  }, [props.timestamp])

  if (!props.timestamp) {
    return null
  }

  return (
    <Common.Box
      borderRadius="4px"
      background="#FF5C5C"
      padding={['4px', '7px']}
      color="white"
      fontSize={['12px', '21px']}
      fontFamily='"Source Sans 3", serif'
    >
      <Icons.Clock />
      <Common.Box as="span" pl={0}>
        {timeLeft}
      </Common.Box>
    </Common.Box>
  )
}

RaffleTimer.propTypes = {
  timestamp: PropTypes.string.isRequired,
}
